<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-4 row-sp-12">
        <div class="pad-top-12 pad-left-8" style="cursor: pointer;" @click="menuModal = true">
          <svg fill="none" height="32" viewBox="0 0 24 24" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.99519C2 5.44556 2.44556 5 2.99519 5H11.0048C11.5544 5 12 5.44556 12 5.99519C12 6.54482 11.5544 6.99039 11.0048 6.99039H2.99519C2.44556 6.99039 2 6.54482 2 5.99519Z" fill="currentColor" /><path d="M2 11.9998C2 11.4501 2.44556 11.0046 2.99519 11.0046H21.0048C21.5544 11.0046 22 11.4501 22 11.9998C22 12.5494 21.5544 12.9949 21.0048 12.9949H2.99519C2.44556 12.9949 2 12.5494 2 11.9998Z" fill="currentColor" /><path d="M2.99519 17.0096C2.44556 17.0096 2 17.4552 2 18.0048C2 18.5544 2.44556 19 2.99519 19H15.0048C15.5544 19 16 18.5544 16 18.0048C16 17.4552 15.5544 17.0096 15.0048 17.0096H2.99519Z" fill="currentColor" /></svg>
        </div>
      </div>
      <div class="col-sp-4 row-sp-12">
        <div class="t-center display-Mobile pad-8 pad-top-12">
          <router-link to="/dashboard">
            <img :src="portalLogo" style="max-height: 40px; height: 100%; width: auto;">
          </router-link>
        </div>
      </div>
      <div class="col-sp-4 row-sp-12">
        <div class="t-right pad-8">
          <div style="display: inline-block; cursor: pointer;" class="mar-right-8">
            <router-link to="/benachrichtigungen" style="position: relative;">
              <svg style="opacity: 0.6;" height="24" version="1.1" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><title /><path d="M18 15.984l2.016 2.016v0.984h-16.031v-0.984l2.016-2.016v-4.969c0-3.094 1.641-5.625 4.5-6.328v-0.703c0-0.844 0.656-1.5 1.5-1.5s1.5 0.656 1.5 1.5v0.703c2.859 0.703 4.5 3.281 4.5 6.328v4.969zM12 21.984c-1.125 0-2.016-0.891-2.016-1.969h4.031c0 1.078-0.938 1.969-2.016 1.969z" /></svg>
              <span v-if="notifications > 0" style="position: absolute;top: 0;right: -5px;background: var(--theme-mendo-primary);color: #ffffff;border-radius: 90px;font-size: 0.8em;width: 12px;height: 12px;line-height: 12px;text-align: center;padding: 2px;">{{ notifications }}</span>
            </router-link>
          </div>
          <div style="display: inline-block; cursor: pointer">
            <router-link to="/profil">
              <img style="vertical-align: -6px;" :src="config.projectURL + 'assets/images/users/avatar_default.png'" class="image width-32 circular">
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
  <nav id="sideBar" class="display-Dektop">
    <div class="pad-8">
      <router-link to="/dashboard">
        <img :src="portalLogo" style="max-height: 40px; height: 100%; width: auto;">
      </router-link>
    </div>
    <ul>
      <li>
        <router-link to="/dashboard" active-class="active">
          <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none" /><path d="M6 26h16v-20h-16v20zm0 16h16v-12h-16v12zm20 0h16v-20h-16v20zm0-36v12h16v-12h-16z" /></svg>
          <span>Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link to="/bestellung" active-class="active">
          <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title /><path d="M12.2,9h1.6V6.8H16V5.2H13.8V3H12.2V5.2H10V6.8h2.2ZM20,5v5.5L7.45,12.72,5,3H1.25a1,1,0,0,0,0,2H3.47L6.7,18H20V16H8.26l-.33-1.33L22,12.18V5ZM7,19a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,7,19Zm12,0a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,19,19Z" /></svg>
          <span>Bestellung</span>
        </router-link>
      </li>
      <li>
        <router-link to="/meinebestellungen" active-class="active">
          <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
          <span>Meine<b style="color: transparent;">.</b>Bestellungen</span>
        </router-link>
      </li>
      <li v-if="user.role">
        <router-link to="/offenebestellungen" active-class="active">
          <svg version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><title /><desc /><defs /><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" transform="translate(-128.000000, -86.000000)"><g id="check-circle-outline" transform="translate(128.000000, 86.000000)"><path d="M5.9,8.1 L4.5,9.5 L9,14 L19,4 L17.6,2.6 L9,11.2 L5.9,8.1 L5.9,8.1 Z M18,10 C18,14.4 14.4,18 10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C10.8,2 11.5,2.1 12.2,2.3 L13.8,0.7 C12.6,0.3 11.3,0 10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 L18,10 L18,10 Z" id="Shape" /></g></g></g></svg>
          <span>Freigabeübersicht</span>
        </router-link>
      </li>
      <li v-if="user.role">
        <router-link to="/bestellhistorie" active-class="active">
          <svg version="1.1" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><title /><desc /><defs /><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" opacity="0.9" transform="translate(-464.000000, -254.000000)"><g id="history" transform="translate(464.000000, 254.500000)"><path d="M10.5,0 C7,0 3.9,1.9 2.3,4.8 L0,2.5 L0,9 L6.5,9 L3.7,6.2 C5,3.7 7.5,2 10.5,2 C14.6,2 18,5.4 18,9.5 C18,13.6 14.6,17 10.5,17 C7.2,17 4.5,14.9 3.4,12 L1.3,12 C2.4,16 6.1,19 10.5,19 C15.8,19 20,14.7 20,9.5 C20,4.3 15.7,0 10.5,0 L10.5,0 Z M9,5 L9,10.1 L13.7,12.9 L14.5,11.6 L10.5,9.2 L10.5,5 L9,5 L9,5 Z" id="Shape" /></g></g></g></svg>
          <span>Bestellhistorie</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profil" active-class="active">
          <img style="vertical-align: -6px;" :src="config.projectURL + 'assets/images/users/avatar_default.png'" class="image circular">
          <span>Mein<b style="color: transparent;">.</b>Profil</span>
        </router-link>
      </li>
      <li>
        <a style="cursor: pointer;" @click="signOutModal = true" active-class="active">
          <svg id="svg8" version="1.1" viewBox="0 0 12.7 12.7" width="48" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"><g id="layer1" transform="translate(0,-284.3)"><path d="M 7.0555553,285.71111 H 2.1166666 v 9.87779 h 4.938889 v -0.70557 H 2.8222222 v -8.46667 h 4.2333334 z" id="path12" style="fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.7055555px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" /><path d="M 4.2333334,289.94445 H 9.172222 l -2.1166667,-2.11667 0.7055557,-0.70555 3.527778,3.52776 -3.527778,3.52779 -0.7055554,-0.70555 2.1166666,-2.11666 H 4.2333334 Z" id="path822" style="opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.7055555px;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" /></g></svg>
          <span>Ausloggen</span>
        </a>
      </li>
    </ul>
  </nav>
  <footer>
    <a class="bottom-cta" @click="SupportModal = true;">
      <svg viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M208 0C322.9 0 416 78.8 416 176C416 273.2 322.9 352 208 352C189.3 352 171.2 349.7 153.9 345.8C123.3 364.8 79.13 384 24.95 384C14.97 384 5.93 378.1 2.018 368.9C-1.896 359.7-.0074 349.1 6.739 341.9C7.26 341.5 29.38 317.4 45.73 285.9C17.18 255.8 0 217.6 0 176C0 78.8 93.13 0 208 0zM164.6 298.1C179.2 302.3 193.8 304 208 304C296.2 304 368 246.6 368 176C368 105.4 296.2 48 208 48C119.8 48 48 105.4 48 176C48 211.2 65.71 237.2 80.57 252.9L104.1 277.8L88.31 308.1C84.74 314.1 80.73 321.9 76.55 328.5C94.26 323.4 111.7 315.5 128.7 304.1L145.4 294.6L164.6 298.1zM441.6 128.2C552 132.4 640 209.5 640 304C640 345.6 622.8 383.8 594.3 413.9C610.6 445.4 632.7 469.5 633.3 469.9C640 477.1 641.9 487.7 637.1 496.9C634.1 506.1 625 512 615 512C560.9 512 516.7 492.8 486.1 473.8C468.8 477.7 450.7 480 432 480C350 480 279.1 439.8 245.2 381.5C262.5 379.2 279.1 375.3 294.9 369.9C322.9 407.1 373.9 432 432 432C446.2 432 460.8 430.3 475.4 426.1L494.6 422.6L511.3 432.1C528.3 443.5 545.7 451.4 563.5 456.5C559.3 449.9 555.3 442.1 551.7 436.1L535.9 405.8L559.4 380.9C574.3 365.3 592 339.2 592 304C592 237.7 528.7 183.1 447.1 176.6L448 176C448 159.5 445.8 143.5 441.6 128.2H441.6z" /></svg>
      <span>Hilfe</span>
    </a>
  </footer>
  <transition name="modal" id="sideMenu">
    <div class="vueModal" v-if="menuModal">
      <div class="vueModalOuter" @click="menuModal = false">
        <div class="menuContent">
          <div class="t-center pad-8">
            <router-link to="/dashboard">
              <img :src="portalLogo" class="width-150">
            </router-link>
          </div>
          <h5>Allgemein</h5>
          <ul class="navTop pad-4" @click="menuModal = false">
            <li>
              <router-link to="/dashboard" active-class="active">
                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none" /><path d="M6 26h16v-20h-16v20zm0 16h16v-12h-16v12zm20 0h16v-20h-16v20zm0-36v12h16v-12h-16z" /></svg>
                Dashboard
              </router-link>
            </li>
            <li>
              <router-link to="/bestellung" active-class="active">
                <svg data-name="Layer 1" id="Layer_1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title /><path d="M12.2,9h1.6V6.8H16V5.2H13.8V3H12.2V5.2H10V6.8h2.2ZM20,5v5.5L7.45,12.72,5,3H1.25a1,1,0,0,0,0,2H3.47L6.7,18H20V16H8.26l-.33-1.33L22,12.18V5ZM7,19a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,7,19Zm12,0a1.5,1.5,0,1,0,1.5,1.5A1.5,1.5,0,0,0,19,19Z" /></svg>
                Bestellung
              </router-link>
            </li>
            <li>
              <router-link to="/meinebestellungen" active-class="active">
                <svg viewBox="0 0 384 512" xmlns="http://www.w3.org/2000/svg"><path d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" /></svg>
                Meine Bestellungen
              </router-link>
            </li>
            <li v-if="user.role">
              <router-link to="/offenebestellungen" active-class="active">
                <svg version="1.1" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><title /><desc /><defs /><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" transform="translate(-128.000000, -86.000000)"><g id="check-circle-outline" transform="translate(128.000000, 86.000000)"><path d="M5.9,8.1 L4.5,9.5 L9,14 L19,4 L17.6,2.6 L9,11.2 L5.9,8.1 L5.9,8.1 Z M18,10 C18,14.4 14.4,18 10,18 C5.6,18 2,14.4 2,10 C2,5.6 5.6,2 10,2 C10.8,2 11.5,2.1 12.2,2.3 L13.8,0.7 C12.6,0.3 11.3,0 10,0 C4.5,0 0,4.5 0,10 C0,15.5 4.5,20 10,20 C15.5,20 20,15.5 20,10 L18,10 L18,10 Z" id="Shape" /></g></g></g></svg>
                Freigabeübersicht
              </router-link>
            </li>
            <li v-if="user.role">
              <router-link to="/bestellhistorie" active-class="active">
                <svg version="1.1" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns" xmlns:xlink="http://www.w3.org/1999/xlink"><title /><desc /><defs /><g fill="none" fill-rule="evenodd" id="Page-1" stroke="none" stroke-width="1"><g fill="#000000" id="Core" opacity="0.9" transform="translate(-464.000000, -254.000000)"><g id="history" transform="translate(464.000000, 254.500000)"><path d="M10.5,0 C7,0 3.9,1.9 2.3,4.8 L0,2.5 L0,9 L6.5,9 L3.7,6.2 C5,3.7 7.5,2 10.5,2 C14.6,2 18,5.4 18,9.5 C18,13.6 14.6,17 10.5,17 C7.2,17 4.5,14.9 3.4,12 L1.3,12 C2.4,16 6.1,19 10.5,19 C15.8,19 20,14.7 20,9.5 C20,4.3 15.7,0 10.5,0 L10.5,0 Z M9,5 L9,10.1 L13.7,12.9 L14.5,11.6 L10.5,9.2 L10.5,5 L9,5 L9,5 Z" id="Shape" /></g></g></g></svg>
                Bestellhistorie
              </router-link>
            </li>
            <li>
              <router-link to="/profil" active-class="active">
                <img style="vertical-align: -6px;" :src="config.projectURL + 'assets/images/users/avatar_default.png'" class="image circular">
                Mein Profil
              </router-link>
            </li>
          </ul>
          <div class="footer bgcolor-primary">
            <div class="grid-12">
              <div class="col-sp-2 row-sp-12 pad-4">
                <img :src="config.projectURL + 'assets/images/users/avatar_default.png'" class="image width-40 circular">
              </div>
              <div class="col-sp-9 row-sp-12 pad-4">
                <h3>{{ user.firstname }} {{ user.lastname }}</h3>
                <h4>{{ user.email }}</h4>
              </div>
              <div class="col-sp-1 row-sp-12 t-right" style="padding-top: 16px;">
                <img style="cursor: pointer; filter: brightness(50);" src="assets/images/icons/sign-out.svg" class="width-24" @click="signOutModal = true">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="signOutModal" style="position: fixed;">
    <div class="vueModal" v-if="signOutModal">
      <div class="vueModalOuter" @click="signOutModal = false">
        <div class="vueModalInner">
          <h6 class="pad-4 t-center f-bold">
            Möchtest du dich wirklich ausloggen?
          </h6>
          <div class="pad-8" />
          <div class="pad-4">
            <button class="button primary fluid" @click="auth.logout();signOutModal = false">
              Abmelden
            </button>
          </div>
          <div class="pad-4">
            <button class="button black fluid" @click="signOutModal = false">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="SupportModal" style="position: fixed;">
    <div class="vueModal" v-if="SupportModal">
      <div class="vueModalOuter">
        <div class="vueModalInner">
          <div>
            <support />
          </div>
          <div class="pad-4">
            <button class="button black fluid" @click="SupportModal = false">
              Schließen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";
import support from "@/components/basic/support";

export default {
  name: "NavBar",
  components: {
    support: support,
  },
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      menuModal: false,
      notificationCount: 0,
      signOutModal: false,
      portalLogo: '',
      primarycolor: 'black',
      secondarycolor: 'black',
      SupportModal: false,
      notifications: 0,
    }
  },
  mounted() {
    this.getPortalLogo();
    this.setColors();
    this.getNotifications();
  },
  methods:{
    getNotifications(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('notifications/getNewNotifications', fD)
          .then((response) => {
            _this.notifications = response.data.length
          })
          .catch(e => {console.error(e)})
    },
    setColors(){
      const _this = this
      const fD = new FormData()
      fD.append('data', 'primarycolor')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.primarycolor = response.data
          })
          .catch(e => {console.error(e)})
      fD.append('data', 'secondarycolor')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.secondarycolor = response.data
          })
          .catch(e => {console.error(e)})
    },
    getPortalLogo(){
      const _this = this
      const fD = new FormData()
      fD.append('data', 'companylogo')
      this.axios.post('dashboard/getData', fD)
          .then((response) => {
            _this.portalLogo = response.data
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 98;
  top: 0px;
  background-color: #ffffff;
}
header img.image{
  border: 2px solid #ffffff;
}
header .menubtn{
  padding: 4px;
  cursor: pointer;
  filter: invert(1);
}
header .menubtn img{
  filter: invert(1);
}
#sideBar{
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 100px;
  background-color: #FFFFFF;
}
#sideBar ul{
  padding: 0;
  margin: 40px 0 0 0;
}
#sideBar ul li{
  text-align: center;
  padding: 0;
  margin: 0 0 24px 0;
}
#sideBar ul li a{
  display: block;
  border-left: 4px solid transparent;
  transition: 0.3s;
  padding: 8px 0;
  position: relative;
}
#sideBar ul li a.active{
  border-left: 4px solid v-bind(primarycolor);
}
#sideBar ul li a:hover{
  border-left: 4px solid v-bind(primarycolor);
  background-color: v-bind(primarycolor);
}
#sideBar ul li svg,
#sideBar ul li img{
  height: 32px;
  opacity: 0.6;
}
#sideBar ul li a:hover svg,
#sideBar ul li a:hover img{
  filter: invert(1);
  opacity: 1;
}
#sideBar ul li a:hover img.circular{
  filter: invert(0);
  opacity: 1;
}
#sideBar ul li span{
  visibility: hidden;
  left: 0;
  top: 0;
  transition: 0.3s;
  position: absolute;
  color: #ffffff;
  padding: 17px 16px 17px 8px;
  font-weight: bold;
  background-color: v-bind(primarycolor);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
#sideBar ul li a:hover span{
  visibility: visible;
  left: 94px;
}

#sideMenu{
  position: fixed;
}
#sideMenu .menuContent{
  width: 100%;
  max-width: 300px;
  position: fixed;
  left: 0;
  background-color: #ffffff;
  height: 100vh;
  top: 0;
  padding: 16px;
  padding-top: 8px;
  z-index: 99;
}
#sideMenu .menuContent h1{
  font-size: 2em;
  padding-left: 8px;
  padding-bottom: 16px;
}
#sideMenu .menuContent h5{
  text-transform: uppercase;
  font-size: 0.8em;
  color: rgba(0,0,0,0.4);
  font-weight: 800;
  margin-top: 32px;
}
#sideMenu .menuContent .navTop{
  padding: 0;
  margin: 0;
  padding-top: 16px;
}
#sideMenu .menuContent .navTop li{
  list-style: none;
  display: block;
  margin-bottom: 24px;
}
#sideMenu .menuContent .navTop li a{
  text-decoration: none;
  display: block;
  color: var(--second-bg-color);
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.2em;
  transition: 0.3s;
}
#sideMenu .menuContent .navTop li a img,
#sideMenu .menuContent .navTop li a svg{
  height: 28px;
  width: 28px;
  vertical-align: -8px;
  margin-right: 8px;
}
#sideMenu .menuContent .navTop li a button{
  float: right;
  margin-top: -4px;
}
#sideMenu .menuContent .navTop li a:hover,
#sideMenu .menuContent .navTop li a.active{
  color: v-bind(primarycolor);
}
#sideMenu .menuContent .footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  padding: 16px;
}
#sideMenu .menuContent .footer img{
  vertical-align: middle;
}
#sideMenu .menuContent .footer h3{
  font-size: 1em;
  font-weight: 700;
  color: #ffffff;
}
#sideMenu .menuContent .footer h4{
  font-size: 1em;
  font-weight: 300;
  color: rgba(255,255,255,0.6);
}
.desktopView{
  display: inline-block;
}
@media (max-width: 768px) {
  .desktopView{
    display: none;
  }
  header{
    background-color: #FAFAFA;
  }
}

.bottom-cta{
  text-align: center;
  position: fixed;
  right: 16px;
  bottom: 16px;
  color: rgba(255,255,255,1);
  background-color: #f70000;
  width: 50px;
  height: 50px;
  border-radius: 90%;
  padding: 4px;
  box-shadow: 1px rgba(0,0,0,0.2);
  z-index: 99;
  transition: 0.3s;
  cursor: pointer;
}
.bottom-cta:hover{
  color: rgba(255,255,255,0.8);
}
.bottom-cta svg{
  display: block;
  margin: 0 auto;
  width: 32px;
  filter: invert(1);
  padding-top: 6px;
}
.bottom-cta span{
  display: block;
  font-size: 10px;
  padding-top: 2px;
}

</style>