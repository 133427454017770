<template>
  <div>
    <main class="content-1400 page-content">
      <div class="pad-4">
        <div class="pad-8">
          <h5 class="color-primary f-normal">
            Übersicht
          </h5>
          <h1>Freigabeübersicht</h1>
        </div>
        <div class="pad-8 t-center" v-if="feedback != ''">
          <span class="label green" v-if="feedback == 'denyOrder'">
            Bestellung wurde erfolgreich abgelehnt.
          </span>
          <span class="label green" v-if="feedback == 'allowOrder'">
            Bestellung wurde erfolgreich genehmigt.
          </span>
        </div>
        <div class="grid-12" v-if="page == ''">
          <div v-for="order in orders" :key="order.id" class="col-sp-12 col-tb-6 row-sp-12 pad-8">
            <div class="bgcolor-white br-10 pad-16 grid-12">
              <div class="col-sp-12 col-tb-4 row-sp-12 pad-8 t-center">
                <img style="max-width: 200px;" class="responsive-img" :src="config.hauptportal+'assets/images/tarife/'+tarife[order.tarif]['tarif_img']">
              </div>
              <div class="col-sp-12 col-tb-8 row-sp-12 pad-8">
                <div class="pad-8" />
                <h3 class="ui header">
                  <div class="content">
                    {{ tarife[order.tarif]['tarif_device'] }}
                    <div class="sub header">
                      {{ tarife[order.tarif]['tarif_name'] }}
                    </div>
                  </div>
                </h3>
                <p>
                  <b>Angefragt von:</b><br>
                  {{ order.contactname }}<br>
                  {{ order.contactemail }}<br><br>
                  <b>Bestellt für:</b><br>
                  {{ order.name }}<br>
                  {{ order.country }}<br>
                  {{ order.street }} {{ order.hnr }}<br>
                  {{ order.plz }} {{ order.city }}<br>
                  <b>Handelt es sich um eine Privatadresse?: </b>
                  <span v-if="order.privatadress == 1">Ja</span>
                  <span v-else>Nein</span>
                  <br>
                </p>
                <div class="t-right mar-top-8">
                  <button class="button fluid primary" @click="page = 'orderdetails';getOrderDetails(order.id)">
                    Ansehen
                  </button>
                </div>
                <div class="pad-4" />
              </div>
            </div>
          </div>
          <div v-if="orders == ''" class="col-sp-12 row-sp-12 pad-24 t-center">
            <p class="color-grey">
              Keine offenen Bestellungen gefunden
            </p>
          </div>
        </div>
        <div class="pad-8" v-else-if="page == 'orderdetails'">
          <div class="mar-bottom-16">
            <button class="button black" @click="page = ''">
              Zurück zur Übersicht
            </button>
          </div>
          <div class="bgcolor-white br-10 pad-16 grid-16">
            <div class="col-sp-4 col-tb-6 row-sp-16 pad-8 t-center">
              <img style="max-width: 200px;" class="responsive-img" :src="config.hauptportal+'assets/images/tarife/'+tarife[orderDetails.tarif]['tarif_img']">
            </div>
            <div class="col-sp-12 col-tb-10 row-sp-16 pad-8">
              <h3 class="ui header">
                <div class="content">
                  {{ tarife[orderDetails.tarif]['tarif_device'] }}
                  <div class="sub header">
                    {{ tarife[orderDetails.tarif]['tarif_name'] }}
                  </div>
                </div>
              </h3>
              <p>
                <b>Firma & Kostenstelle:</b><br>
                {{ orderDetails.company }}<br>
                {{ orderDetails.kostenstelle }}<br><br>
                <b>WBS Element:</b><br>
                {{ orderDetails.wbs }}<br><br>
                <b>Angefragt von:</b><br>
                {{ orderDetails.contactname }}<br>
                {{ orderDetails.contactemail }}<br><br>
                <b>Bestellt für:</b><br>
                {{ orderDetails.name }}<br>
                {{ orderDetails.country }}<br>
                {{ orderDetails.street }} {{ orderDetails.hnr }}<br>
                {{ orderDetails.plz }} {{ orderDetails.city }}<br>
                <b>Handelt es sich um eine Privatadresse?: </b>
                <span v-if="orderDetails.privatadress == 1">Ja</span>
                <span v-else>Nein</span>
                <br><br>
                <b>Erstgerät/Austauschgerät: </b><br>
                <span v-if="orderDetails.austausch == 1">Austauschgerät</span>
                <span v-else>Erstgerät</span>
                <br><br>
                <b>Gerät:</b><br>
                Rufnummer: [{{ orderDetails.geraetphonenumber }}]
              </p>
              <p v-if=" orderDetails.austausch == 1">
                <br>Gerätetyp: [{{ orderDetails.devicetype }}]
                <br>Kaufdatum: [{{ orderDetails.contractfrom }}]
              </p>
              <p v-if=" orderDetails.tarif == 7">
                <br><br>
                <b>Bestellung:</b><br>
                {{ orderDetails.other_order }}
              </p>
              <p>
                <br><br>
                <b>Zusatznachricht:</b><br>
                [{{ orderDetails.reason }}] {{ orderDetails.message }}<br><br>
                <b>Anmerkungen:</b><br>
                {{ orderDetails.order_note }}<br><br>
              </p>
              <div class="pad-4" />
            </div>
            <div class="col-sp-16 col-sd-8 row-sp-16 pad-8" style="position: relative;">
              <button style="position: absolute; bottom: 8px; width: calc(100% - 16px);" @click="denyModal = true" class="ui right labeled icon fluid red button">
                Ablehnen <i class="cancel icon" />
              </button>
            </div>
            <div class="col-sp-16 col-sd-8 row-sp-16 pad-8">
              <form @submit.prevent="allowOrder(orderDetails.user_id,orderDetails.email)">
                <div v-if="orderDetails.austausch == 0">
                  <div class="field">
                    <label>Tarifauswahl:</label>
                    <select v-model="holcimtarif">
                      <option :value="item.name" v-for="item in profiles" :key="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="field mar-top-8">
                  <label>Notiz für Mendo Consult:</label>
                  <textarea v-model="comment" rows="2" placeholder="Notiz für Mendo Consult" />
                </div>
                <button type="submit" class="button fluid black">
                  Genehmigen
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
    <transition name="modal" id="denyModal" style="position: fixed;">
      <div class="vueModal" v-if="denyModal">
        <div class="vueModalOuter">
          <div class="vueModalInner">
            <div>
              <form @submit.prevent="denyOrder(orderDetails.user_id,orderDetails.email)">
                <div class="field pad-4">
                  <label>Begründung der Ablehnung</label>
                  <textarea rows="3" placeholder="Beschreibung" v-model="reason" />
                </div>
                <div class="pad-4">
                  <button type="submit" class="button primary fluid">
                    Ablehnen
                  </button>
                </div>
              </form>
            </div>
            <div class="pad-4">
              <button class="button black fluid" @click="denyModal = false">
                Schließen
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";

export default {
  name: "OpenOrders",
  title: "Bestellung",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      orders: '',
      page: '',
      tarife: '',
      orderDetails: '',
      holcimtarif: '',
      comment: '',
      feedback: '',
      reason: '',
      denyModal: false,
      profiles: []
    }
  },
  mounted() {
    this.getTarife()
    this.getOpenOrders()
  },
  methods: {
    getOpenOrders(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getOpenOrders', fD)
          .then((response) => {
            if(response.status === 250){
              _this.orders = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getOrderDetails(orderId){
      this.orderId = orderId
      const _this = this
      const fD = new FormData()
      fD.append('id', orderId)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getOrderById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.orderDetails = response.data
              this.getProfiles(response.data.tarif)
            }
          })
          .catch(e => {console.error(e)})
    },
    getProfiles(tarifNumber){
      const _this = this
      const fD = new FormData()
      fD.append('tarifNumber', tarifNumber)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('dashboard/getProfiles', fD)
          .then((response) => {
            if(response.status === 250){
              _this.profiles = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    getTarife(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/getAllTarife', fD)
          .then((response) => {
            if(response.status === 250){
              _this.tarife = response.data
            }
          })
          .catch(e => {console.error(e)})
    },
    allowOrder(userid, email){
      const _this = this
      const fD = new FormData()
      fD.append('userid', userid)
      fD.append('email', email)
      fD.append('orderid', this.orderId)
      fD.append('holcimtarif', this.holcimtarif)
      fD.append('comment', this.comment)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/allowOrder', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.feedback = 'allowOrder'
              _this.page = ''
              _this.getOpenOrders()
            }
          })
          .catch(e => {console.error(e)})
    },
    denyOrder(userid, email){
      const _this = this
      const fD = new FormData()
      fD.append('userid', userid)
      fD.append('email', email)
      fD.append('orderid', this.orderId)
      fD.append('reason', this.reason)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('bestellung/denyOrder', fD)
          .then((response) => {
            console.log(response)
            if(response.status === 250){
              _this.feedback = 'denyOrder'
              _this.page = ''
              _this.denyModal = false
              _this.getOpenOrders()
            }
          })
          .catch(e => {console.error(e)})
    }
  }
}
</script>

<style scoped>

</style>